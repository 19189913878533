<template>
  <div class="words-history">
    <header class="header">
      <span class="title">删除记录</span>
      <el-button type="primary" @click="$router.go(-1)">返回</el-button>
    </header>
    <DragTable :list-query="list" :header="header">
    </DragTable>
    <footer class="table-footer">
      <p></p>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="params.page"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="params.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </footer>
  </div>
</template>
<script>
import { defineComponent, reactive, ref } from "@vue/composition-api";

export default defineComponent({
  setup(props,{ root }) {
    const list = reactive({ data: [] });
    const total = ref(0)
    const type = {
      1: '白名单',
      2: '屏蔽词',
      3: '多词组',
      5: '高危词',
      6: '正常词',
    }
    let header = [
      { name: "序号", value: "id" },
      { name: "词条", value: "word" },
      { name: "词库类型", value: "type" },
      { name: "删除人", value: "operator" },
      { name: "删除日期", value: "create_at" },
    ]
    if(root.$route.query.type == 'multiple') {
      header = [
      { name: "序号", value: "id" },
      { name: "词条", value: "word" },
      { name: "条件词条1", value: "first_related_word" },
      { name: "条件词条2", value: "second_related_word" },
      { name: "词库类型", value: "type" },
      { name: "删除人", value: "operator" },
      { name: "删除日期", value: "create_at" },
    ]
    }
    const datetime = ref("");
    const params = reactive({
      page: 1,
      limit: 10,
      word: undefined,
      dict_id: root.$route.query.id
    });
    const getList = () => {
      root.$axios
        .get("/rcp/dictionaryManage/dictionaryDelete/list", {
          params,
        })
        .then((res) => {
          list.data = res.data.list;
          total.value = res.data.total;
        });
    };
    getList()
    const handleSizeChange = (size) => {
      params.limit = size;
      params.page = 1
      getList();
    };
    const handleCurrentChange = (index) => {
      params.page = index;
      getList();
    };
    return {
      total,
      list,
      header,
      params,
      datetime,
      type,
      handleCurrentChange,
      handleSizeChange
    };
  },
});
</script>
<style lang="scss" scoped>
.words-history{
  display: flex;
  flex-direction: column;
  .header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>